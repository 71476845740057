<template>
    <div class="product-item">
          <div class="image-container">
            <img :src="item.imgUrl?item.imgUrl:'https://image.mizongkeji.com/asinImages/egg.png'" alt="商品图片"/>
          </div>
          <div class="product-list-detail">
            <div v-if="item.asin" :class="'product-sku '+skuClass">
                <slot :itemData="item" name="asin">
                    ASIN：{{item.asin}}
                </slot>
            </div>
            <div v-if="item.sku" :class="'product-sku '+ skuClass">
                    <slot :itemData="item" name="sku">
                        SKU：{{item.sku}}
                </slot>
                </div>
            <div v-if="item.fnsku" class="product-sku">
                    <slot :itemData="item" name="fnsku">
                        FNSKU：{{item.fnsku}}
                </slot>
                </div>
          </div>
    </div>
</template>

<script>
export default {
    props: {
        item:Object,
        skuClass:{
            type:String,
            default:''
        }
    }
}
</script>
