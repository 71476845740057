<template>
    <div class="grid">
        <div class="col-12 xl:col-4">
            <div class="card">
                <div class="field">
                    <label for="orderId">订单编号</label>
                    <InputText id="orderId" type="text" v-model="orderId" style="width:100%"/>
                </div>

                <div class="field">
                    <label for="name">收件人</label>
                    <InputText id="name" type="text" v-model="address.name" style="width:100%"/>
                </div>

                <div class="field">
                    <label for="addressline">详细地址</label>
                    <InputText id="addressline" type="text" v-model="address.addressline" style="width:100%"/>
                </div>

                <div class="field">
                    <label for="city" >城市</label>
                    <InputText id="city" type="text" v-model="address.city" style="width:100%"/>
                </div>
                <div class="grid">
                    <div class="field col-6">
                        <label for="stateOrRegion" >州</label>
                        <Dropdown id="stateOrRegion" v-model="address.stateOrRegion" :options="states" optionLabel="label" optionValue="code" style="width:100%"/>
                    </div>
                    <div class="field col-6">
                        <label for="postalCode" >邮编</label>
                        <InputText id="postalCode" type="text" v-model="address.postalCode" style="width:100%"/>
                    </div>
                </div>
                <div class="grid">
                    <div class="field col-6">
                        <label for="phone" >电话(可选)</label>
                        <InputText id="phone" type="text" v-model="address.phone" style="width:100%"/>
                    </div>
                    <div class="field col-6">
                        <label for="email" >电子邮件(可选)</label>
                        <InputText id="email" type="text" v-model="address.email" style="width:100%"/>
                    </div>
                </div>

                <div class="field">
                    <label for="displayableOrderComment">备注留言</label>
                    <Textarea id="displayableOrderComment" v-model="displayableOrderComment" :autoResize="true" style="width:100%" />
                </div>
            </div>
        </div>
        <div class="col-12 xl:col-4">
            <div class="card">
                选择店铺：<Dropdown style="width:auto"  v-model="selectedStore" :options="stores" optionLabel="storeName" optionValue="sellerId" placeholder="选择店铺"/>
                搜索商品：
                <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="搜索FNSKU、SKU、ASIN" />
                </span>
                <DataTable :value="items" class="p-datatable-sm mt-3" stripedRows :paginator="true" :rows="5" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink" v-model:filters="filters" :globalFilterFields="['asin','sku','fnsku']">
                    <Column field="fnsku" header="商品信息">
                        <template #body="{data}">
                            <itemInfo :item="{imgUrl:data.smallImage,asin:data.asin,sku:data.sku,fnsku:data.fnsku}" />
                        </template>
                    </Column>
                    <Column field="inventory" header="库存">

                    </Column>
                    <Column field="qty" header="数量">
                        <template #body="{data}">
                            <InputNumber v-model="data.qty" showButtons :step="1" :allowEmpty="false" inputStyle="width:40px;" mode="decimal" :min="0" :max="parseInt(data.inventory)"  @input="qtyChange()"/>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <div class="col-12 xl:col-4">
            <div class="card">
                已选择的产品:
                <DataTable :value="selectItems" class="p-datatable-sm mt-3" stripedRows :paginator="true" :rows="5" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink">
                    <Column field="fnsku" header="商品信息">
                        <template #body="{data}">
                            <itemInfo :item="{imgUrl:data.smallImage,asin:data.asin,sku:data.sku,fnsku:data.fnsku}" />
                        </template>
                    </Column>
                    <Column field="qty" header="数量">
                        <template #body="{data}">
                            {{data.qty}}
                        </template>
                    </Column>
                </DataTable>
                <div class="field-radiobutton">
                    <RadioButton id="shippingSpeedCategory" value="Standard" v-model="shippingSpeedCategory" />
                    <label for="shippingSpeedCategory">标准配送</label>
                </div>
                <Button type="button" label="创建订单" @click="createOrder()" />
                <Button class="ml-3" type="button" :label="resetlabel" @click="initAll()" />
                <Message v-for="msg of messages" :severity="msg.severity" :key="msg.id">{{msg.content}}</Message>
            </div>
        </div>
    </div>
</template>
<script>
import Service from '../../service/Service';
import {FilterMatchMode} from 'primevue/api';
import itemInfo from '../common/itemInfo.vue';
export default {
    data(){
        return{
            resetlabel:'重新开始',
            selectedStore:null,
            stores: [],
            address:{
                name:null,
                addressline:null,
                city:null,
                districtOrCounty:null,
                stateOrRegion:'AL',
                countryCode:'US',
                postalCode:null,
                email:null,
                phone:null,
            },
            orderId:null,
            displayableOrderComment:'Thank you for your order!',
            items:[],
            selectItems:[],
            filters: {},
            shippingSpeedCategory:'Standard',
            states:[
            {label:'AL-Alabama',code:'AL'},
            {label:'AK-Alaska',code:'AK'},
            {label:'AZ-Arizona',code:'AZ'},
            {label:'AR-Arkansas',code:'AR'},
            {label:'CA-California',code:'CA'},
            {label:'CO-Colorado',code:'CO'},
            {label:'CT-Connecticut',code:'CT'},
            {label:'DE-Delaware',code:'DE'},
            {label:'FL-Florida',code:'FL'},
            {label:'GA-Georgia',code:'GA'},
            {label:'HI-Hawaii',code:'HI'},
            {label:'ID-Idaho',code:'ID'},
            {label:'IL-Illinois',code:'IL'},
            {label:'IN-Indiana',code:'IN'},
            {label:'IA-Iowa',code:'IA'},
            {label:'KS-Kansas',code:'KS'},
            {label:'KY-Kentucky',code:'KY'},
            {label:'LA-Louisiana',code:'LA'},
            {label:'ME-Maine',code:'ME'},
            {label:'MD-Maryland',code:'MD'},
            {label:'MA-Massachusetts',code:'MA'},
            {label:'MI-Michigan',code:'MI'},
            {label:'MN-Minnesota',code:'MN'},
            {label:'MS-Mississippi',code:'MS'},
            {label:'MO-Missouri',code:'MO'},
            {label:'MT-Montana',code:'MT'},
            {label:'NE-Nebraska',code:'NE'},
            {label:'NV-Nevada',code:'NV'},
            {label:'NH-New Hampshire',code:'NH'},
            {label:'NJ-New Jersey',code:'NJ'},
            {label:'NM-New Mexico',code:'NM'},
            {label:'NY-New York',code:'NY'},
            {label:'NC-North Carolina',code:'NC'},
            {label:'ND-North Dakota',code:'ND'},
            {label:'OH-Ohio',code:'OH'},
            {label:'OK-Oklahoma',code:'OK'},
            {label:'OR-Oregon',code:'OR'},
            {label:'PA-Pennsylvania',code:'PA'},
            {label:'RI-Rhode Island',code:'RI'},
            {label:'SC-South Carolina',code:'SC'},
            {label:'SD-South Dakota',code:'SD'},
            {label:'TN-Tennessee',code:'TN'},
            {label:'TX-Texas',code:'TX'},
            {label:'UT-Utah',code:'UT'},
            {label:'VT-Vermont',code:'VT'},
            {label:'VA-Virginia',code:'VA'},
            {label:'WA-Washington',code:'WA'},
            {label:'WV-West Virginia',code:'WV'},
            {label:'WI-Wisconsin',code:'WI'},
            {label:'WY-Wyoming',code:'WY'}
            ],
            messages: []
        }
    },
    created(){
        this.service = new Service();
        this.initFilters();
    },
    mounted() {
        this.stores=JSON.parse(localStorage.getItem('stores'));
    },
    watch:{
        selectedStore:function(){
            this.confirmStore();
        },
    },
    methods:{
        initAll(){
            this.address={
                name:null,
                addressline:null,
                city:null,
                districtOrCounty:null,
                stateOrRegion:'AL',
                countryCode:'US',
                postalCode:null,
                email:null,
                phone:null,
            };
            this.selectedStore=null;
            this.orderId=null;
            this.items=[];
            this.selectItems=[];
            this.messages=[];
        },
        createOrder(){
            this.messages=[];
            var items=[];
            var d=new Date();
            this.selectItems.forEach(item=>{
                var newitem={
                    sellerSku:item.sku,
                    sellerFulfillmentOrderItemId:d.valueOf()+'00'+(items.length+1),
                    quantity:item.qty
                };
                items.push(newitem);
            });
            var orderdata={
                sellerId:this.selectedStore,
                items:items,
                destinationAddress:this.address,
                sellerFulfillmentOrderId:this.orderId,
                displayableOrderId:this.orderId,
                displayableOrderDate:d.toISOString(),
                displayableOrderComment:this.displayableOrderComment,
                shippingSpeedCategory:this.shippingSpeedCategory
            };
            var url='order/createfbafulfillmentorder.php';
            this.service.sendHttp(url,'POST',JSON.stringify(orderdata),true).then(res=>res.json()).then(data => {
                if(data.result=='success'){
                    this.resetlabel='继续下单';
                    this.messages.push({severity: 'success', content: '创建成功！', id: 1});
                }else{
                    this.messages.push({severity: 'error', content: '创建失败！'+data.message, id: 1});
                }
            });
        },
        confirmStore(){
                if(this.selectedStore!=null){
                    this.items=[];
                    this.selectItems=[];
                    var url='order/getitemlist.php?sellerid='+this.selectedStore;
                    this.service.sendHttp(url).then(res=>res.json()).then(data => {
                        data.forEach(item=>{
                            var newitem={
                                asin:item.asin,
                                sku:item.sku,
                                fnsku:item.fnsku,
                                smallImage:item.smallImage,
                                qty:0,
                                inventory:item.inventory
                            };
                            this.items.push(newitem);
                        });
                    });
                }
        },
        qtyChange() {
            this.selectItems=this.items.filter(item=>item.qty>0);
        },
        initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
            }
        },
    },
    components: {
        'itemInfo': itemInfo
    }
}
</script>